import React from 'react'
import './App.css';
import { ComingSoon } from './components/comingSoon';

function App() {
  return (
    <div className='App'>
      <ComingSoon />
    </div>
  )
}

export default App;
